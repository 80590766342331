import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR, { KeyedMutator } from "swr";
import Button from "../../../components/Button/Button";
import DangerButton from "../../../components/DangerButton/DangerButton";
import Event from "../../../constants/interfaces/Event";

const AdminEvents = () => {
  const { getToken } = useKindeAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  const {
    data,
    isLoading,
    mutate,
  }: { data: Event[]; isLoading: boolean; mutate: KeyedMutator<any> } = useSWR(
    `${process.env.REACT_APP_API_ENDPOINT}/events`,
    fetcher,
  );

  const deleteEvent = async (id: string) => {
    const accessToken = await getToken();

    if (window.confirm(t("admin.areYouSure", { value: t("events.event") }))) {
      await axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/events/${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          mutate();
        });
    }
  };

  const eventTypeIcon = (eventType: string) => {
    switch (eventType) {
      case "challenge":
        return light("trophy");
      case "clear":
        return light("block-brick");
      case "foodFight":
        return light("soft-serve");
      case "item":
        return light("cards-blank");
      case "photoHunt":
        return light("camera");
      case "stardust":
        return light("stars");
      case "survival":
        return light("bomb");
      case "tagTeam":
        return light("handshake");
      case "villain":
        return light("skull");
      default:
        return light("square-question");
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-2">
        <FontAwesomeIcon
          className="h-7 w-7 text-purple-500 dark:text-purple-400"
          icon={light("calendar")}
        />

        <h1 className="text-xl font-bold text-slate-900 dark:text-slate-100">
          {t("events.title")}
        </h1>
      </div>

      <Button
        className="flex w-full gap-2"
        label={`${t("add")} ${t("events.event")}`}
        onClick={() => navigate("/admin/events/add")}
      >
        <FontAwesomeIcon
          className="h-5 w-5"
          icon={light("add")}
        ></FontAwesomeIcon>
        {t("add")} {t("events.event")}
      </Button>

      {!isLoading && !data?.length ? (
        <div className="relative mt-4 rounded border border-yellow-600 bg-yellow-500 p-2 text-center text-sm text-white dark:border-yellow-500 dark:bg-yellow-400 dark:text-yellow-900">
          <FontAwesomeIcon
            className="h-6 w-6"
            icon={light("square-dashed-circle-plus")}
          />
          <p>{t("admin.empty", { value: t("events.title") })}</p>
        </div>
      ) : (
        <table className="w-full text-slate-600 dark:text-slate-300">
          <thead className="border-b-2 border-b-current">
            <tr>
              <th colSpan={2} scope="col"></th>
              <th className="hidden p-2 text-left sm:table-cell" scope="col">
                {t("events.startDate")}
              </th>
              <th className="hidden p-2 text-left sm:table-cell" scope="col">
                {t("events.endDate")}
              </th>
              <th className="w-[38px] p-2" scope="col"></th>
              <th className="w-[38px] pl-2" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data?.length
              ? data?.map((event) => {
                  return (
                    <tr
                      className="even:bg-slate-200/50 even:dark:bg-slate-800/50"
                      key={event?._id}
                    >
                      <td className="w-5 p-2">
                        <FontAwesomeIcon
                          className="h-5 w-5 text-purple-500 dark:text-purple-400"
                          fixedWidth
                          icon={eventTypeIcon(event.type)}
                        ></FontAwesomeIcon>
                      </td>
                      <th className="rounded-l pr-2 text-left" scope="row">
                        <div className="flex items-center gap-2">
                          {event.type === "villain"
                            ? Array.isArray(event.villain) && event.villain.length > 1
                              ? t("multi")
                              : t(`emojis.${event.villain}`)
                            : event.name}
                          {event.type === "villain" &&
                          Array.isArray(event.villain) && event.villain.length > 1
                            ? ""
                            : " "}
                          {t(`eventTypes.${event.type}`)}{" "}
                          {event.type === "stardust"
                            ? t("events.challenge")
                            : t("events.event")}
                          {new Date(event.startDate) <= new Date() &&
                            new Date(event.endDate) >= new Date() && (
                              <span className="relative h-3 w-3">
                                <span className="absolute flex h-3 w-3 animate-ping rounded-full bg-green-500 opacity-75"></span>
                                <span className="absolute flex h-3 w-3 rounded-full bg-green-500"></span>
                              </span>
                            )}
                        </div>
                      </th>
                      <td className="hidden p-2 sm:table-cell">
                        {new Date(event.startDate).toLocaleDateString(
                          undefined,
                          {
                            timeZone: "UTC",
                          },
                        )}
                      </td>
                      <td className="hidden p-2 sm:table-cell">
                        {new Date(event.endDate).toLocaleDateString(undefined, {
                          timeZone: "UTC",
                        })}
                      </td>
                      <td className="p-2">
                        <Button
                          label={`${t("edit")} ${t("events.event")}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/admin/events/edit/${event?._id}`);
                          }}
                        >
                          <FontAwesomeIcon
                            className="h-5 w-5"
                            icon={light("pencil")}
                          ></FontAwesomeIcon>
                        </Button>
                      </td>
                      <td className="rounded-r pl-2">
                        <DangerButton
                          onClick={(e) => {
                            e.preventDefault();
                            deleteEvent(event?._id);
                          }}
                        ></DangerButton>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      )}
    </>
  );
};

export default AdminEvents;
