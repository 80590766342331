import {
  duotone,
  light,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { IKImage } from "imagekitio-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Theme } from "../../constants/enums/Theme";
import Emoji from "../../constants/interfaces/Emoji";
import { useFormatArray } from "../../store/Utils";
import AvailableFrom from "../AvailableFrom/AvailableFrom";
import DialogCloseButton from "../DialogCloseButton/DialogCloseButton";
import EmojiFlair from "../EmojiFlairPopover/EmojiFlairPopover";
import EmojiTriggers from "../EmojiTriggers/EmojiTriggers";
import Level from "../Level/Level";

type EmojiDetailsProps = {
  emoji: Emoji;
  favourite: boolean;
  setFavourite: any;
  level: number;
  setLevel: any;
  show: boolean;
  setShow: any;
};

const EmojiDetails = ({
  emoji,
  favourite,
  setFavourite,
  level,
  setLevel,
  show,
  setShow,
}: EmojiDetailsProps) => {
  const [cardRotation, setCardRotation] = useState("");
  const [canScrollDown, setCanScrollDown] = useState(false);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const [scrollable, setScrollable] = useState(false);
  const { getToken, isAuthenticated, login, user, getPermission } =
    useKindeAuth();
  const { t } = useTranslation();
  const blitzModifiers = useFormatArray(
    emoji.blitzModifiers?.map((blitzModifier) =>
      t(`blitzModifiers.${blitzModifier}`),
    ),
  );
  const borderBoxGradient = {
    silver: "before:bg-gradient-silver-to-t",
    gold: "before:bg-gradient-gold-to-t",
    series1: "before:bg-gradient-series-to-t",
    series2: "before:bg-gradient-series-to-t",
    series3: "before:bg-gradient-series-to-t",
    story: "before:bg-gradient-story-to-t",
    rainbow: "before:bg-gradient-rainbow-to-br",
    villain: "before:bg-gradient-villain-to-t",
    crystal: "before:bg-gradient-crystal-to-br",
    platinum: "before:bg-gradient-platinum-to-tr",
    cozyCrafts: "before:bg-gradient-cozy-crafts-45deg",
  };
  const positionBoxGradient = {
    silver: "bg-gradient-silver-to-t",
    gold: "bg-gradient-gold-to-t",
    series1: "bg-gradient-gold-to-t",
    series2: "bg-gradient-gold-to-t",
    series3: "bg-gradient-gold-to-t",
    story: "bg-gradient-story-to-t",
    rainbow: "bg-gradient-rainbow-to-br",
    villain: "bg-gradient-villain-to-t",
    crystal: "bg-gradient-crystal-to-br",
    platinum: "bg-gradient-platinum-to-tr",
    cozyCrafts: "bg-indigo-500",
  };
  const closeModal = () => {
    setShow(false);
  };
  const formatLevels = (levels: number[]) => {
    if (
      // If there are more than 1 level and the numbers are consecutive
      levels.length > 1 &&
      levels.every(
        (level: number, index: number) =>
          (levels[index + 1] || level + 1) - level === 1,
      )
    ) {
      if (levels.length === emoji.maxLevel) {
        // If the numbers of levels is the same as the emoji's max. level, then return "All"
        return t("all");
      } else {
        // Else return a range of numbers, lowest to highest separated by a hyphen
        return `${levels[0]}-${levels[levels.length - 1]}`;
      }
    } else {
      // Return a formatted array if only 1 level, or the levels are not consecutive
      return levels?.length > 1
        ? `${levels?.slice(0, -1).join(", ")} ${t("and")} ${levels?.slice(-1)}`
        : levels?.toString();
    }
  };
  const groups = useFormatArray(
    emoji.groups &&
      [...emoji.groups]
        .sort((a, b) => t(`groups.${a}`).localeCompare(t(`groups.${b}`)))
        .map((group) => t(`groups.${group}`)),
  );
  const mql = window.matchMedia("(prefers-color-scheme: dark)");
  const scrollRef = useRef<HTMLDivElement>(null);
  const tags = useFormatArray(
    [...emoji.tags]
      .sort((a, b) => t(`tags.${a}`).localeCompare(t(`tags.${b}`)))
      .map((tag) => t(`tags.${tag}`)),
  );
  const worlds = useFormatArray(
    emoji.worlds &&
      [...emoji.worlds]
        .sort((a, b) => t(`worlds.${a}`).localeCompare(t(`worlds.${b}`)))
        .map((world) => t(`worlds.${world}`)),
  );

  const handleFavourite = async () => {
    if (isAuthenticated) {
      const accessToken = await getToken();

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/favourites/${user?.id}`,
          {
            emoji: emoji._id,
            favourite: !favourite,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then(() => {
          setFavourite(!favourite);
        });
    }
  };

  const handleLevel = async (level: number) => {
    if (isAuthenticated) {
      const accessToken = await getToken();

      axios
        .put(
          `${process.env.REACT_APP_API_ENDPOINT}/levels/${user?.id}`,
          {
            emoji: emoji._id,
            level,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        )
        .then(() => {
          setLevel(level);
        });
    }
  };

  const formatRecommendation = (recommendation: string) => {
    if (recommendation.startsWith("combo")) {
      const images = recommendation
        .slice(5)
        .split("Plus")
        .map((image) => image.charAt(0).toLocaleLowerCase() + image.slice(1));

      return (
        <span className="flex items-center">
          {t("recommendations.combo")}&nbsp;
          <IKImage
            alt={t(`recommendations.${images[0]}`)}
            className="h-4"
            height="16"
            lqip={{ active: true }}
            path={`${images[0]}.png`}
            transformation={[
              {
                dpr: window.devicePixelRatio.toString(),
                width: "16",
              },
            ]}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
            width="16"
          />
          &nbsp;+&nbsp;
          {images[1] === "item" ? (
            t("recommendations.item")
          ) : (
            <IKImage
              alt={t(`recommendations.${images[1]}`)}
              className="h-4"
              height="16"
              lqip={{ active: true }}
              path={`${images[1]}.png`}
              transformation={[
                {
                  dpr: window.devicePixelRatio.toString(),
                  width: "16",
                },
              ]}
              urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
              width="16"
            />
          )}
        </span>
      );
    } else {
      return t(`recommendations.${recommendation}`);
    }
  };

  useEffect(() => {
    const current = scrollRef.current;

    if (current) {
      setCanScrollUp(current.scrollTop > 0);
      setCanScrollDown(
        current.scrollTop + current.clientHeight < current.scrollHeight,
      );
      setScrollable(current.scrollHeight > current.clientHeight);
    }

    setCurrentTheme(() => {
      if (
        localStorage.getItem("theme") === Theme.Dark ||
        ((!localStorage.getItem("theme") ||
          localStorage.getItem("theme") === Theme.Auto) &&
          mql.matches)
      ) {
        return Theme.Dark;
      } else {
        return Theme.Light;
      }
    });

    mql.onchange = () => {
      setCurrentTheme(localStorage.getItem("theme") as Theme);
    };
  }, [mql]);

  useEffect(() => {
    const rotations = ["-rotate-2", "-rotate-1", "rotate-1", "rotate-2"];

    setCardRotation(rotations[Math.round(Math.random() * 3)]);
  }, []);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-2 text-center">
          <TransitionChild
            as={Fragment}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <div className="fixed inset-0 bg-slate-100/20 backdrop-blur backdrop-filter dark:bg-slate-900/20" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="transform transition duration-500"
            enterFrom="opacity-0 -translate-y-full"
            enterTo={`opacity-100 translate-y-0`}
          >
            <DialogPanel
              className={`${
                borderBoxGradient[emoji.box as keyof typeof borderBoxGradient]
              } ${emoji.box === "cozyCrafts" ? "before:shadow-[0_2px_2px_#fff7ed_inset,0_-2px_2px_#7c2d12_inset] before:outline before:outline-1 before:-outline-offset-1 before:outline-orange-600 after:pointer-events-none after:absolute after:left-0 after:top-0 after:block after:h-full after:w-full after:rounded-xl after:outline-dashed after:outline-2 after:-outline-offset-[6px] after:outline-indigo-500 after:content-['']" : null} ${cardRotation} relative my-2 inline-block w-full max-w-sm overflow-hidden rounded-xl bg-white p-1 text-left align-middle shadow-xl before:absolute before:inset-0 before:rounded-xl before:p-1 dark:bg-slate-700 dark:before:border-opacity-90`}
            >
              <div
                className={`${emoji.box === "cozyCrafts" ? "from-white to-cyan-300 dark:from-cyan-600 dark:to-cyan-900" : "from-white/75 to-cyan-300/75 dark:from-cyan-600/75 dark:to-cyan-900/75"} relative flex h-36 w-full items-end justify-center overflow-hidden rounded-t-lg bg-gradient-radial p-2`}
              >
                {/* Position */}
                <div
                  className={`${
                    positionBoxGradient[
                      emoji.box as keyof typeof positionBoxGradient
                    ]
                  } absolute left-3 top-3 flex h-12 w-12 flex-wrap content-center justify-center rounded-full shadow-md before:absolute before:top-0.5 before:h-11 before:w-11 before:rounded-full before:bg-gradient-to-t before:from-transparent before:via-transparent before:to-white after:absolute after:bottom-0.5 after:h-11 after:w-11 after:rounded-full after:bg-gradient-to-b after:from-transparent after:via-transparent after:to-black/30`}
                >
                  <div className="relative z-10 text-sm font-bold text-white drop-shadow-[0_1px_2px_rgba(0,0,0,0.75)] dark:text-slate-100">
                    {emoji.position !== undefined &&
                      `#${(emoji.position + 1)?.toString().padStart(3, "0")}`}
                  </div>
                  {(emoji.box === "crystal" || emoji.box === "platinum") && (
                    <FontAwesomeIcon
                      className="absolute right-0 top-0 animate-[pulse_1s_ease-in-out_infinite] text-white drop-shadow-md"
                      icon={duotone("sparkles")}
                    ></FontAwesomeIcon>
                  )}
                </div>

                {/* Cloud */}
                <FontAwesomeIcon
                  className="absolute -bottom-32 h-64 w-64 text-white opacity-60 drop-shadow-2xl"
                  icon={solid("cloud")}
                ></FontAwesomeIcon>

                {/* Shadow */}
                <div className="absolute -bottom-4 h-16 w-32 rounded-full bg-gradient-radial from-cyan-700/50 via-transparent to-transparent dark:from-cyan-900 dark:via-transparent dark:to-transparent"></div>

                {/* Emoji */}
                {emoji.filePath ? (
                  <IKImage
                    alt={t(`emojis.${emoji._id}`)}
                    className="relative z-10 h-32 w-32"
                    height="128"
                    lqip={{ active: true }}
                    path={emoji.filePath}
                    transformation={[
                      {
                        di:
                          currentTheme === Theme.Dark
                            ? "dark-error.png"
                            : "error.png",
                        dpr: window.devicePixelRatio.toString(),
                        width: "128",
                        height: "128",
                        cropMode: "pad_resize",
                      },
                    ]}
                    urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
                    width="128"
                  />
                ) : (
                  <span className="fa-layers fa-fw fa-7x relative z-10">
                    <FontAwesomeIcon
                      icon={solid("circle")}
                      className="text-white dark:text-slate-900"
                    />
                    <FontAwesomeIcon
                      icon={light("face-dotted")}
                      className="relative z-10 text-purple-500 dark:text-purple-400"
                    />
                  </span>
                )}

                {/* Close button */}
                <DialogCloseButton
                  className="absolute right-3 top-3 !text-cyan-800 dark:!text-cyan-100"
                  onClick={closeModal}
                />
              </div>
              <div
                className={`${emoji.box === "cozyCrafts" ? "bg-gradient-cozy-crafts-light-to-tr dark:bg-gradient-cozy-crafts-dark-to-tr" : "bg-slate-100/80 dark:bg-slate-900/80"} relative flex h-96 flex-col gap-2 rounded-b-lg p-3`}
              >
                <div className="flex justify-between gap-2">
                  {/* Emoji name */}
                  <DialogTitle
                    as="h3"
                    className="text-xl font-bold leading-6 text-slate-900 dark:text-slate-100"
                  >
                    <>{t(`emojis.${emoji._id}`)}</>
                  </DialogTitle>

                  <div className="flex items-start gap-4">
                    {/* Edit */}
                    {isAuthenticated && getPermission("admin").isGranted && (
                      <Link to={`/admin/emojis/edit/${emoji._id}`}>
                        <FontAwesomeIcon
                          className="h-6 w-6 text-purple-500 dark:text-purple-400"
                          icon={light("pen-to-square")}
                        />
                      </Link>
                    )}

                    {/* Level */}
                    <Level
                      arrayTo={emoji.maxLevel + 1}
                      disabled={
                        !(
                          emoji.availableFrom &&
                          new Date(emoji.availableFrom) < new Date()
                        )
                      }
                      level={level}
                      onClick={handleLevel}
                    ></Level>

                    {/* Favourite */}
                    <button
                      name="Favourite"
                      onClick={
                        isAuthenticated ? handleFavourite : (login as any)
                      }
                      type="button"
                    >
                      <FontAwesomeIcon
                        className="h-6 w-6 shrink-0 text-red-500 dark:text-red-400"
                        icon={favourite ? solid("heart") : light("heart")}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex gap-2">
                  {/* Flair */}
                  <EmojiFlair emoji={emoji} />

                  {/* Available from */}
                  <AvailableFrom
                    date={emoji.availableFrom}
                    showPastDates={true}
                  />
                </div>

                <div className="mt-2 overflow-hidden rounded-lg border border-slate-500/25 bg-white/60 dark:border-slate-950/25 dark:bg-slate-900/60">
                  <div
                    className={`details ${
                      scrollable && "details--scrollable"
                    } ${scrollable && !canScrollUp && "details--at-top"} ${
                      scrollable && !canScrollDown && "details--at-bottom"
                    } h-full overflow-auto rounded-lg`}
                    onScroll={() => {
                      setCanScrollUp(
                        scrollRef.current
                          ? scrollRef.current.scrollTop > 0
                          : false,
                      );
                      setCanScrollDown(
                        scrollRef.current
                          ? scrollRef.current.scrollTop +
                              scrollRef.current.clientHeight +
                              1 <
                              scrollRef.current.scrollHeight
                          : false,
                      );
                    }}
                    ref={scrollRef}
                  >
                    {/* Tags */}
                    {tags && tags.length > 0 && (
                      <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                        <FontAwesomeIcon
                          className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
                          icon={light("tag")}
                        />
                        <div className="pl-2">
                          <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                            {t("tags.title")}
                          </p>
                          <p className="py-1 text-xs text-slate-500 dark:text-slate-300">
                            {tags}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Worlds */}
                    {worlds && worlds.length > 0 && (
                      <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                        <FontAwesomeIcon
                          className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
                          icon={light("globe")}
                        />
                        <div className="pl-2">
                          <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                            {t("worlds.title")}
                          </p>
                          <p className="py-1 text-xs text-slate-500 dark:text-slate-300">
                            {worlds}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Powers */}
                    {Object.keys(emoji.powers).length > 0 && (
                      <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                        <FontAwesomeIcon
                          className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
                          icon={light("bolt")}
                        />
                        <div className="w-full pl-2">
                          <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                            {t("powers.title")}
                          </p>
                          <table className="w-full text-left text-xs text-slate-500 dark:text-slate-300">
                            <thead className="border-b-2 border-b-current">
                              <tr>
                                <th className="p-1 pl-0" colSpan={2}></th>
                                <th className="p-1 pr-0 text-right">
                                  {t("levels")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(emoji.powers).map(
                                ([power, levels]) => (
                                  <tr key={power}>
                                    <td className="w-7 p-1 pl-0">
                                      <IKImage
                                        alt={t(`powers.${power}`)}
                                        height="24"
                                        lqip={{ active: true }}
                                        path={`${power}.png`}
                                        transformation={[
                                          {
                                            di:
                                              currentTheme === Theme.Dark
                                                ? "dark-error.png"
                                                : "error.png",
                                            dpr: window.devicePixelRatio.toString(),
                                            width: "24",
                                          },
                                        ]}
                                        urlEndpoint={
                                          process.env
                                            .REACT_APP_IMAGEKIT_URL_ENDPOINT
                                        }
                                        width="24"
                                      />
                                    </td>
                                    <th className="p-1">
                                      {t(`powers.${power}`)}
                                    </th>
                                    <td className="p-1 pr-0 text-right">
                                      {(levels && formatLevels(levels)) || (
                                        <FontAwesomeIcon
                                          className="text-yellow-500 dark:text-yellow-400"
                                          icon={solid("exclamation-triangle")}
                                        ></FontAwesomeIcon>
                                      )}
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {/* Blitz Modifiers */}
                    {blitzModifiers && blitzModifiers.length > 0 && (
                      <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                        <FontAwesomeIcon
                          className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
                          icon={light("cloud-rainbow")}
                        />
                        <div className="pl-2">
                          <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                            {t("blitzModifiers.title")}
                          </p>
                          <p className="py-1 text-xs text-slate-500 dark:text-slate-300">
                            {blitzModifiers}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Recommendations */}
                    {emoji.recommendations &&
                      Object.keys(emoji.recommendations).length > 0 && (
                        <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                          <FontAwesomeIcon
                            className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
                            icon={light("lightbulb-exclamation")}
                          />
                          <div className="w-full pl-2">
                            <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                              {t("recommendations.title")}
                            </p>
                            <table className="w-full text-left text-xs text-slate-500 dark:text-slate-300">
                              <thead className="border-b-2 border-b-current">
                                <tr>
                                  <th className="p-1 pl-0"></th>
                                  <th className="p-1 pr-0 text-right">
                                    {t("levels")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(emoji.recommendations).map(
                                  ([recommendation, levels]) => (
                                    <tr key={recommendation}>
                                      <th className="p-1 pl-0">
                                        {formatRecommendation(recommendation)}
                                      </th>
                                      <td className="p-1 pr-0 text-right">
                                        {(levels && formatLevels(levels)) || (
                                          <FontAwesomeIcon
                                            className="text-yellow-500 dark:text-yellow-400"
                                            icon={solid("exclamation-triangle")}
                                          ></FontAwesomeIcon>
                                        )}
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}

                    {/* Groups */}
                    {groups && groups.length > 0 && (
                      <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                        <FontAwesomeIcon
                          className="mx-1 h-6 w-6 shrink-0 text-purple-500 dark:text-purple-400"
                          icon={light("user-group")}
                        />
                        <div className="pl-2">
                          <p className="py-0.5 text-sm font-bold text-purple-500 dark:text-purple-400">
                            {t("groups.title")}
                          </p>
                          <p className="py-1 text-xs text-slate-500 dark:text-slate-300">
                            {groups}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Triggers */}
                    {emoji.triggers && (
                      <div className="flex p-3 pl-2 even:bg-slate-300/25 dark:even:bg-slate-950/25">
                        <EmojiTriggers
                          level={level}
                          showHeader={true}
                          toTriggerAverage={emoji.toTriggerAverage}
                          triggers={emoji.triggers}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {emoji.box === "cozyCrafts" && (
                <div className="pointer-events-none absolute left-0 top-0 h-full w-full rounded-xl bg-[url('./assets/images/black-felt.png')] mix-blend-difference"></div>
              )}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmojiDetails;
