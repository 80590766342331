import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IKImage } from "imagekitio-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../constants/enums/Theme";
import Emoji from "../../constants/interfaces/Emoji";
import { useAppSelector } from "../../store/Hooks";
import EmojiDetails from "../EmojiDetails/EmojiDetails";

type GridProps = {
  emoji: Emoji;
};

const Grid = ({ emoji }: GridProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "emojis" });
  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const [favourite, setFavourite] = useState(emoji.favourite);
  const [isVisible, setIsVisible] = useState(false);
  const [level, setLevel] = useState(emoji.level);
  const [show, setShow] = useState(false);
  const { isAuthenticated } = useKindeAuth();
  const boxBorderClassNames = {
    silver: "before:bg-gradient-silver-to-t",
    gold: "before:bg-gradient-gold-to-t",
    series1: "before:bg-gradient-gold-to-t",
    series2: "before:bg-gradient-gold-to-t",
    series3: "before:bg-gradient-gold-to-t",
    story: "before:bg-gradient-story-to-t",
    rainbow: "before:bg-gradient-rainbow-to-br",
    villain: "before:bg-gradient-villain-to-t",
    crystal: "before:bg-gradient-crystal-to-br",
    platinum: "before:bg-gradient-platinum-to-tr",
    cozyCrafts: "before:bg-gradient-cozy-crafts-45deg",
  };
  const boxShieldClassNames = {
    silver: "bg-gradient-silver-to-t",
    gold: "bg-gradient-gold-to-t",
    series1: "bg-gradient-gold-to-t",
    series2: "bg-gradient-gold-to-t",
    series3: "bg-gradient-gold-to-t",
    story: "bg-gradient-story-to-t",
    rainbow: "bg-gradient-rainbow-to-br",
    villain: "bg-gradient-villain-to-t",
    crystal: "bg-gradient-crystal-to-br",
    platinum: "bg-gradient-platinum-to-tr",
    cozyCrafts: "bg-gradient-cozy-crafts-45deg",
  };
  const mql = window.matchMedia("(prefers-color-scheme: dark)");
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    },
    [],
  );
  const isLoading = useAppSelector((state) => state.loading.isLoading);
  const ref = useRef<HTMLDivElement>(null);
  const showAnimatePulse = isLoading && isVisible ? "animate-pulse" : "";

  const levelIcon = (level: number) => {
    switch (level) {
      case 1:
        return solid("1");
      case 2:
        return solid("2");
      case 3:
        return solid("3");
      case 4:
        return solid("4");
      case 5:
        return solid("5");
      default:
        return solid("0");
    }
  };

  useEffect(() => {
    setCurrentTheme(() => {
      if (
        localStorage.getItem("theme") === Theme.Dark ||
        ((!localStorage.getItem("theme") ||
          localStorage.getItem("theme") === Theme.Auto) &&
          mql.matches)
      ) {
        return Theme.Dark;
      } else {
        return Theme.Light;
      }
    });

    mql.onchange = () => {
      setCurrentTheme(localStorage.getItem("theme") as Theme);
    };
  }, [mql]);

  useEffect(() => {
    const current = ref.current;
    const observer = new IntersectionObserver(handleIntersection);

    setLevel(emoji.level);

    if (current) {
      observer.observe(current);

      return () => observer.unobserve(current);
    }
  }, [emoji.level, handleIntersection]);

  return (
    <>
      <div ref={ref}>
        {!isLoading && isVisible ? (
          <button
            className={`${
              boxBorderClassNames[emoji.box as keyof typeof boxBorderClassNames]
            } ${
              !(
                emoji.availableFrom &&
                new Date(emoji.availableFrom) < new Date()
              )
                ? "!from-blue-300 !to-blue-600 dark:!from-blue-500 dark:!to-blue-800"
                : ""
            } ${emoji.box === "cozyCrafts" ? "outline-dashed outline-2 -outline-offset-[6px] outline-indigo-500 before:shadow-[0_2px_2px_#fff7ed_inset,0_-2px_2px_#7c2d12_inset] before:outline before:outline-1 before:-outline-offset-1 before:outline-orange-600 after:pointer-events-none after:absolute after:left-0 after:top-0 after:h-full after:w-full after:rounded-3xl after:bg-[url('./assets/images/black-felt.png')] after:mix-blend-difference" : null} emoji-border relative block h-20 w-20 shrink-0 rounded-3xl bg-gradient-radial from-white to-cyan-300 p-2 shadow before:absolute before:inset-0 before:rounded-3xl before:p-1 before:content-[''] dark:from-cyan-200 dark:to-cyan-500 dark:before:border-opacity-90`}
            onClick={() => setShow(true)}
            type="button"
          >
            {emoji.filePath ? (
              <IKImage
                alt={t(emoji._id)}
                className={`${
                  !(
                    emoji.availableFrom &&
                    new Date(emoji.availableFrom) < new Date()
                  )
                    ? "mix-blend-multiply"
                    : ""
                } relative`}
                height="64"
                loading="lazy"
                lqip={{ active: true }}
                path={emoji.filePath}
                transformation={[
                  {
                    di:
                      currentTheme === Theme.Dark
                        ? "dark-error.png"
                        : "error.png",
                    dpr: window.devicePixelRatio.toString(),
                    width: "64",
                    height: "64",
                    cropMode: "pad_resize",
                  },
                ]}
                urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT}
                width="64"
              />
            ) : (
              <FontAwesomeIcon
                icon={light("face-dotted")}
                size="3x"
                className="text-cyan-900 opacity-50 dark:text-cyan-100"
              />
            )}
            {isAuthenticated && (
              <div className="absolute -bottom-1 -left-1 h-6 w-6">
                {emoji.availableFrom &&
                  new Date(emoji.availableFrom) < new Date() && (
                    <>
                      <FontAwesomeIcon
                        icon={solid("shield")}
                        className="absolute left-0 h-6 w-6 opacity-50 blur-sm"
                      />
                      <div
                        className={`${
                          boxShieldClassNames[
                            emoji.box as keyof typeof boxShieldClassNames
                          ]
                        } emoji-shield absolute h-6 w-6`}
                      >
                        <span className="fa-layers fa-fw h-6 w-6">
                          <FontAwesomeIcon
                            className="h-6 w-6 opacity-90 blur-[1px]"
                            icon={levelIcon(level)}
                            transform="shrink-8"
                          />
                          <FontAwesomeIcon
                            className="h-6 w-6 text-white"
                            icon={levelIcon(level)}
                            transform="shrink-8 up-0.5"
                          />
                        </span>
                      </div>
                    </>
                  )}
              </div>
            )}
          </button>
        ) : (
          <div
            className={`${showAnimatePulse} h-20 w-20 rounded-3xl bg-slate-200 dark:bg-slate-700`}
          ></div>
        )}
      </div>

      {/* Emoji Details modal */}
      {show && (
        <EmojiDetails
          emoji={emoji}
          favourite={favourite}
          setFavourite={setFavourite}
          level={level}
          setLevel={setLevel}
          show={show}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default Grid;
